<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else class="tabs-container">
		<div class="tabs-wrapper" :class="{ 'hide-tabs': isPaymentGroup }">
			<CTabs>
				<CTab
					v-for="item in paymentMethodGroupDetails.paymentMethods"
					:key="item.id"
					:title="item.name_en"
					:active="item.id === methodId"
					:to="{ name: 'PaymentMethodSetting', params: { id: $route.params.id, methodId: item.id} }"
				>
					<div class="c-main">
						<div class="container-fluid">
							<PaymentSettingGeneral
								v-if="item.id === Number(methodId)"
								:data="data"
								:group-id="Number(id)"
								:method-id="Number(methodId)"
								:is-updating="isUpdating"
								:is-payment-group="isPaymentGroup"
								:group-slug="paymentMethodGroupDetails.slug"
								@onSubmit="handleSubmit"
							/>
						</div>
					</div>
				</CTab>
			</CTabs>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PaymentSettingGeneral from '@/components/PaymentSettingGeneral.vue';
import {
	PAYMENT_BILL_PAYMENT_SLUG,
	PAYMENT_COD_SLUG,
	PAYMENT_COUNTER_PAYMENT_SLUG,
	PAYMENT_QR_SLUG,
	PAYMENT_PAY_AT_STORE_SLUG,
	PAYMENT_PAY_AT_STORE_UFUND_SLUG,
	PAYMENT_PAY_ON_PICK_UP_SLUG,
} from '../enums/payments';

const paymentGroupSlugs = [
	PAYMENT_COD_SLUG,
	PAYMENT_QR_SLUG,
	PAYMENT_BILL_PAYMENT_SLUG,
	PAYMENT_COUNTER_PAYMENT_SLUG,
	PAYMENT_PAY_AT_STORE_SLUG,
	PAYMENT_PAY_AT_STORE_UFUND_SLUG,
	PAYMENT_PAY_ON_PICK_UP_SLUG,
];

export default {
	name: 'PaymentSetting',

	components: {
		PaymentSettingGeneral,
	},
	data() {
		return {
			id: this.$route.params.id,
			methodId: null,
			isLoading: false,
			isUpdating: false,
			data: null,
		};
	},
	computed: {
		...mapState('payments', {
			paymentGroup: 'paymentGroup',
		}),
		...mapGetters({
			paymentMethodGroupDetails: 'payments/paymentMethodGroupDetails',
			paymentMethodDetails: 'payments/paymentMethodDetails',
		}),

		// Payment group - COD, Pay at store, Pay on pick up, QR, Bill payment, Counter service
		isPaymentGroup() {
			return paymentGroupSlugs.includes(this.paymentMethodGroupDetails.slug);
		},
	},
	async created() {
		this.isLoading = true;

		await this.getPaymentMethodGroup(this.id);

		// Find method id from payment group if not exist
		if (this.$route.params.methodId) {
			this.methodId = Number(this.$route.params.methodId);
		} else {
			this.methodId = this.paymentMethodGroupDetails.paymentMethods[0].id;
		}

		if (!this.isPaymentGroup) {
			await this.getPaymentMethod(this.methodId);
		}

		this.data = this.isPaymentGroup
			? this.paymentMethodGroupDetails
			: this.paymentMethodDetails;

		// Set page title
		this.setPageTitle({
			title: this.paymentMethodGroupDetails.name || null,
			isShow: true,
		});

		this.isLoading = false;
	},
	methods: {
		...mapActions({
			getPaymentMethod: 'payments/getPaymentMethod',
			getPaymentMethodGroup: 'payments/getPaymentMethodGroup',
			updatePaymentMethod: 'payments/updatePaymentMethod',
			updatePaymentMethodGroup: 'payments/updatePaymentMethodGroup',
			setPageTitle: 'pageTitle/setPageTitle',
		}),
		async handleSubmit(params) {
			const id = this.isPaymentGroup ? this.id : this.methodId;
			this.isUpdating = true;

			if (this.isPaymentGroup) {
				await this.updatePaymentMethodGroup({ id, params });
			} else {
				await this.updatePaymentMethod({ id, params });
			}

			this.isUpdating = false;
		},
	},
};
</script>
